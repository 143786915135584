import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const BASE_URL = 'https://65c0a295dc74300bce8c6530.mockapi.io/users/users';

export const getAllPasswords = createAsyncThunk(
  'passwords/passwords',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios(BASE_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const postUser = createAsyncThunk(
  'user/user',
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(BASE_URL, user);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`${BASE_URL}/${id}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
