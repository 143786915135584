import { useSelector } from 'react-redux';
import { selectCheckUser } from '../../redux/selectors';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
  const user = useSelector(selectCheckUser);

  if (user === 'Alexander') {
    return children;
  }

  return <Navigate to="/" />;
};
