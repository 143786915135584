import styled from 'styled-components';

export const BoxMenu = styled.div`
  position: absolute;
  width: 100%;
  height: max-content;
  left: 0;
  top: 95px;
  background-color: white;
  z-index: 100;
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-around;
  }
`;

export const BoxWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const List = styled.ul`
  margin-top: 20px;
`;

export const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Link = styled.a`
  font-weight: 500;
  color: rgb(0, 0, 255);

  &:hover {
    text-decoration: underline 3px solid blue;
  }
`;

export const Description = styled.p`
  margin-top: 5px;
  font-weight: 500;
`;
