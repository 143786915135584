import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const HeaderStyled = styled.header`
  background-color: rgb(60, 107, 236);
`;

export const LinkStyled = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: white;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const ButtonStyled = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    left: 0;
    top: 18px;
    background-color: white;
    opacity: 0;
    transition: 500ms linear opacity;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;
