/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BoxMenu,
  BoxWrapper,
  List,
  Item,
  Link,
  Description,
} from './Menu.styled';

export const Menu = () => {
  return (
    <BoxMenu>
      <BoxWrapper>
        <h2>Services and information</h2>
        <List>
          <Item>
            <Link href="https://www.gov.uk/browse/benefits">Benefits</Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/births-deaths-marriages">
              Births, death, marriages and care
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/business">
              Business and self-employed
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/childcare-parenting">
              Childcare and parenting
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/citizenship">
              Citizenship and living in the UK
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/justice">
              Crime, justice and the law
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/disabilities">
              Disabled people
            </Link>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/browse/driving">
              Driving and transport
            </Link>
          </Item>
        </List>
      </BoxWrapper>
      <BoxWrapper>
        <h2>Government activity</h2>
        <List>
          <Item>
            <Link href="https://www.gov.uk/government/organisations">
              Departments
            </Link>
            <Description>Departments, agencies and public bodies</Description>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/search/news-and-communications">
              News
            </Link>
            <Description>
              News stories, speeches, letters and notices
            </Description>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/search/guidance-and-regulation">
              Guidance and regulation
            </Link>
            <Description>Detailed guidance, regulations and rules</Description>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/search/research-and-statistics">
              Research and statistics
            </Link>
            <Description>Reports, analysis and official statistics</Description>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/search/policy-papers-and-consultations">
              Policy papers and consultations
            </Link>
            <Description>Consultations and strategy</Description>
          </Item>
          <Item>
            <Link href="https://www.gov.uk/search/transparency-and-freedom-of-information-releases">
              Transparency
            </Link>
            <Description>
              Data, Freedom of Information releases and corporate reports
            </Description>
          </Item>
        </List>
      </BoxWrapper>
    </BoxMenu>
  );
};
