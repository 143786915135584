import { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllPasswords } from './redux/passwords/passwords';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'components/Layout/Layout';
import { PrivateRoute } from '../src/components/HOCs/PrivateRoute';

const Home = lazy(() => import('../src/pages/Home/Home.jsx'));
const Admin = lazy(() => import('../src/pages/Admin/Admin.jsx'));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPasswords());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
