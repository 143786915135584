import { createSlice } from '@reduxjs/toolkit';
import { getAllPasswords, postUser, deleteUser } from './passwords';

const passwordsSlice = createSlice({
  name: 'passwords',
  initialState: {
    passwords: [],
    isLoading: false,
    error: null,
    checkUser: null,
  },

  reducers: {
    checkUser: (state, { payload }) => {
      state.checkUser = payload;
    },
  },

  extraReducers: builder =>
    builder
      .addCase(getAllPasswords.fulfilled, (state, { payload }) => {
        state.passwords = payload;
        state.isLoading = false;
      })
      .addCase(getAllPasswords.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAllPasswords.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(postUser.fulfilled, (state, { payload }) => {
        state.passwords = [...state.passwords, payload];
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        const users = state.passwords.filter(({ id }) => id !== payload.id);
        state.passwords = users;
      }),
});

export const passwordsReducer = passwordsSlice.reducer;
export const { checkUser } = passwordsSlice.actions;
